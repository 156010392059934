const config = {
  contractAddress: process.env.VUE_APP_REGISTRY_CONTRACT_ADDRESS,
  dataAggregatorContract: process.env.VUE_APP_DATA_AGGREGATOR_CONTRACT,
  multisigContract: process.env.VUE_APP_MULTISIG_CONTRACT_ADDRESS,
  bscAPIKey: process.env.VUE_APP_BSC_API_KEY,
  mainNetworkId: process.env.VUE_APP_MAIN_NETWORK_ID,
  testNetworkId: process.env.VUE_APP_TEST_NETWORK_ID,
  API: process.env.VUE_APP_API_BASE_URL || 'http://127.0.0.1:8000',
  supportMail: process.env.VUE_APP_SUPPORT_EMAIL,
  btcExplorerUrl: process.env.VUE_APP_BTC_EXPLORER
}

export default config;