<template>
  <header class="header">
    <!--  -->
      <!-- v-if="type === 'regular'" -->
    <div
      class="w-100 px-4 d-flex justify-content-between align-items-center"
    >
      <div class="container d-flex align-items-center justify-content-between">
        <router-link to="/" class="logo me-4">
          <img src="@/assets/images/logo-dark.svg" alt="" />
          <!-- {{loggedIn}} -->
        </router-link>

        <nav class="header-middle navigation">
          <router-link :class="currentRoute === '/blso' || currentRoute === '/' ? 'active' : ''" to="/blso">BLSO</router-link>
          <router-link :class="currentRoute === '/investments' ? 'active' : ''" to="/investments">Investments</router-link>
          <router-link :class="currentRoute === '/settings' ? 'active' : ''" to="/settings">Settings</router-link>
        </nav>
        <!-- <div class="btcPrice"></div> -->
        <div @mouseleave="showPopover = false" style="min-height: 89px;" v-if="loggedIn" class="header-user-info-wrapper d-flex align-items-center">
          <nav
            @mouseenter="showPopover = true"
            class="d-flex header-user-info align-items-center flex-nowrap position-relative"
            :class="isActive ? 'nav active' : 'nav'"
          >
            <div class="user position-relative">
              <img
                @click="openUserMenu"
                class="open-dropdown"
                src="@/assets/images/user.svg"
                alt=""
              />
            </div>
            <p v-if="userData" class="ms-3 mb-0">
              {{ userData.email }}
            </p>
            <template v-if="userData">
              <div @mouseleave="showPopover = false" :class="showPopover ? 'active' : ''" class="user-popover">
                <h4>Account information</h4>
                <p>You are currently signed in as:</p>
                <p class="blue-text">{{userData.email}}</p>
                <button @click="logout" class="button w-100">Sign Out</button>
              </div>
            </template>
          </nav>
        </div>
        <nav v-else class="header-auth-btns">
          <router-link to="/login"
            >Sign In</router-link
          >
          <router-link to="/register">Register</router-link>
        </nav>
        <!-- @click="test(!isActive)" -->
        <a
          href="#"
          :class="showMobileMenu ? 'navicon active' : 'navicon'"
          @click="showMobileMenu = !showMobileMenu"
        >
          <i></i>
          <i></i>
          <i></i>
        </a>
      </div>
    </div>

    <!--  -->
    <!-- <div
      class="container d-flex justify-content-between align-items-center"
      v-if="type === 'login'"
    >
      <router-link to="/" class="logo">
        <img src="@/assets/images/logo-dark.svg" alt="" />
      </router-link>

      <nav class="header-auth-btns" >
        <router-link to="/login" :class="active === 'login' && active"
          >Sign In</router-link
        >
        <router-link to="/register">Register</router-link>
      </nav>

      <a
        href="#"
        :class="showMobileMenu ? 'navicon active' : 'navicon'"
        @click="showMobileMenu = !showMobileMenu"
      >
        <i></i>
        <i></i>
        <i></i>
      </a>
    </div> -->
    <div :class="showMobileMenu ? 'active' : ''" class="mobile-menu">
      <!-- <p @click="showMobileMenu = false" class="close">✕</p> -->
      <div class="d-flex flex-column align-items-center">
        <router-link @click="showMobileMenu = false" to="/">BLSO</router-link>
        <router-link @click="showMobileMenu = false" to="/investments">Investments</router-link>
        <router-link @click="showMobileMenu = false" to="/settings">Settings</router-link>
        <template v-if="!loggedIn">
          <router-link class="button white" @click="showMobileMenu = false" to="/login">Sign In</router-link>
          <router-link class="button white" @click="showMobileMenu = false" to="/register">Register</router-link>
        </template>
        <div v-if="loggedIn && userData && userData.email" class="mt-5">
          <span class="d-inline-block mb-4 blue-text">{{userData.email}}</span>
          <button @click="logout" class="mt-2 button w-100">Sign Out</button>
        </div>
      </div>
    </div>
    <!--  -->
    <div
      class="container d-flex justify-content-between align-items-center"
      v-if="type === 'minimal'"
    >
      <router-link to="/" class="logo">
        <img src="@/assets/images/logo-dark.svg" alt="" />
      </router-link>

      <nav :class="isActive ? 'nav active header-user-wrapper' : 'nav'">
        <router-link to="/settings" class="user"
          ><img src="@/assets/images/user.svg" alt="" />Kwadwo</router-link
        >
      </nav>

      <a
        href="#"
        :class="isActive ? 'navicon active' : 'navicon'"
        @click="test(!isActive)"
      >
        <i></i>
        <i></i>
        <i></i>
      </a>
    </div>
  </header>
</template>

<script lang="js">
import { defineComponent } from "vue";
import copyIcon from '../assets/images/copy.svg';
import redirect from '../assets/images/redirect.svg';
import config from '../config.js';
import { shortenAddress } from '../helpers.js';

export default defineComponent({
	name: "Header",
	data: function () {
    return {
      isActive: false,
      userMenuOpen: false,
      // showWalletModal: true,
      showAccInfoModal: false,
      copyIcon: copyIcon,
      redirect: redirect,
      wrongNetwork: false,
      balances:[],
      accessToken:null,
      shortenAddress: shortenAddress,
      loggedIn: false,
      userData: null,
      currentRoute: null,
      showPopover: false,
      showMobileMenu: false,
      resizeListener: null
    };
  },
  components: {
    // WalletModal,
  },
  props: {
    type: {
      type: String,
      required: false,
      // If not required, it's possible to give a default value
      default: "regular",
    },
  },
  mounted() {
    window.addEventListener('resize', this.closeMobileMenu);
    console.log('header type prop??? ', this.type);
    this.$store.watch(state => {
      this.userData = state.userData;
      this.accessToken = state.accessToken;
      this.loggedIn = state.loggedIn;
      this.currentRoute = state.currentRoute;
    });

    this.$store.subscribe((mutation, state) => {
      // if (mutation.type === 'refreshBalances') {
      //     this.getBalances();
      // }
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.closeMobileMenu);
  },
  methods: {
    closeMobileMenu() {
      this.showMobileMenu = false;
    },
    async logout() {
        this.showPopover = false;
        this.showMobileMenu = false;
        await window.localStorage.setItem('accessToken', '');
        this.$store.dispatch('setLoggedIn', false);
        this.$store.dispatch('setAccessToken', null);
        this.$router.push('/login');
    },
     getBalances() {
        // fetch(`${config.API}/api/v1/balances`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Accept': 'application/json',
        //         'Authorization': 'Bearer ' + this.accessToken
        //     },
        // })
        // .then(res => res.json())
        // .then(data => {
        //     console.log(data);
        //     this.balances = data?.data?.balances;
        // })
        // .catch(error => {
        //     console.log(error);
        // })
    },
    copyAddress() {
        navigator.clipboard.writeText(this.metamaskAddress);
        this.$toast.success('Copied!');
    },
    test(tab) {
      console.log(tab);
      this.isActive = tab;
    },
    openUserMenu() {
      this.userMenuOpen = !this.userMenuOpen;
    },
    closeModal() {
        // this.showWalletModal = false;
        this.showAccInfoModal = false;
    }
  },
});
</script>
