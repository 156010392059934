<template>
  <footer class="footer">
    <div class="container footer-container d-flex justify-content-between align-items-start align-items-lg-center">

      <div class="d-flex flex-wrap flex-lg-nowrap">
        <a href="https://www.ten31.com/" class="logo d-flex order-1">
          <img class="footer-logo" style="min-width: 120px;" src="@/assets/images/logo-light.svg" alt=""/>
        </a>
        <div style="white-space: nowrap" class="footer-text order-3 order-lg-2">This BLSO Platform was created by TEN31 Bank AG</div>
      </div>

      <nav class="nav order-2 order-lg-3 my-4 my-lg-0">
        <div class="footer-links">
          <a target="_blank" href="mailto:sales@ten31.com" to="/">Contact</a>
          <a target="_blank" href="https://digital.ten31.com/about">About</a>
          <a target="_blank" href="https://real-estate.ten31.com/en/imprint">Imprint</a>
          <a target="_blank" href="https://real-estate.ten31.com/en/privacy">Privacy</a>
        </div>
        <div class="d-flex justify-content-between icon-links">
          <a target="_blank" class="link-icon" href="https://twitter.com/TEN31Bank">
            <img src="@/assets/images/twitter.svg" alt=""/>
          </a>
          <a target="_blank" class="link-icon" href="https://www.linkedin.com/company/ten31-bank?trk=similar-pages_result-card_full-click">
            <img src="@/assets/images/linkedin.svg" alt=""/>
          </a>
          <a target="_blank" class="link-icon" href="https://www.facebook.com/TEN31bank/">
            <img src="@/assets/images/facebook.svg" alt=""/>
          </a>
        </div>
      </nav>

    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
});
</script>
