import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/kyc-error',
    name: 'KYC Error',
    component: () => import('../views/user/KYCError.vue')
  },
  {
    path: '/blso',
    name: 'BLSO',
    component: () => import('../views/user/STO.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/user/404.vue'),
  },
  {
    path: '/investments',
    name: 'Investments',
    component: () => import('../views/user/Investments.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue'),
  },
  {
    path: '/reset-password',
    name: 'Reset password',
    component: () => import('../views/auth/ResetPassword.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/Register.vue'),
  },
  {
    path: '/already-verified',
    name: 'Verified',
    component: () => import('../views/auth/Verified.vue'),
  },
  {
    path: '/verify-success',
    name: 'Success',
    component: () => import('../views/auth/Success.vue')
  },
  {
    path: '/new-password',
    name: 'New Password',
    component: () => import('../views/auth/NewPassword.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/user/Settings.vue'),
  }

];

const router = createRouter({
  history: createWebHistory('/'),
  routes
});

export default router;
