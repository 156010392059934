import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Maska from 'maska';
import Toaster from '@meforma/vue-toaster';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

/* eslint-disable */

const authRouteNames = [
    'Register',
    'Login',
    'Reset password'
];

// route guarding
router.beforeEach((to, from, next) => {
    const userStatus = store.state.userData;
    if (to.path === '/' || (authRouteNames.includes(to.name as any) && store.state.loggedIn)) {
        next({ name: 'BLSO' });
    } else if (userStatus?.status === 4 && to.name !== 'Country Mismatch') {
        next({ name: 'Country Mismatch' });
    } else if (to.name === 'Country Mismatch' && userStatus?.status !== 4) {
        next({ name: 'Investments' });
    } else if (!router.hasRoute(to.name as any)) {
        next({name: '404'});
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    store.dispatch('setCurrentRoute', to.path);
});

const app = createApp(App);

// sentry setup
Sentry.init({
    app,
    dsn: "https://c404fd10529143feadf7ecb1126eda2b@o993999.ingest.sentry.io/6149488",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["weguser.omniapps.info/", /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
});

// init vue app
createApp(App).use(Toaster).use(store).use(router).use(Maska).mount('#app');